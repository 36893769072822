import React from "react"
import clsx from "clsx"

import Container from "@ecom/ui/components/Container"

import { advantages } from "./helpers"

import * as styles from "./fourAdvantagesPoints.module.scss"
import { Variants } from "./types"

type FourAdvantagesPointsProps = {
  variant?: Variants
  orderNum?: string
  withTitle?: boolean
  alignTitleLeft?: boolean
  indent?: boolean
  title?: string
}

export const FourAdvantagesPoints = ({
  variant = "pkw",
  orderNum,
  withTitle = false,
  alignTitleLeft = true,
  indent = true,
  title = "Ваша выгода с Халвой:",
}: FourAdvantagesPointsProps) => (
  <section className={clsx(styles.section, styles[variant])} data-exclude={orderNum}>
    <Container>
      {withTitle && (
        <h2
          className={clsx(
            styles.headTitle,
            {
              [styles.left]: alignTitleLeft,
              [styles.indent]: indent,
            },
            styles[variant]
          )}
        >
          {title}
        </h2>
      )}
      <div className={styles.wrapper}>
        {advantages[variant].map((item, idx) => (
          <div className={clsx(styles.wrap, styles[variant])} key={idx}>
            <div className={styles.img}>{item.img}</div>
            <div className={styles.text}>
              <div className={clsx(styles.title, styles[variant])}>
                {item.title}
                {item.dashed && <div className={styles.dashed} />}
              </div>
              {item.subtitle && (
                <div
                  className={clsx(styles.subtitle, styles[variant])}
                  dangerouslySetInnerHTML={{ __html: item?.subtitle }}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </Container>
  </section>
)
