import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage, GatsbyImageProps } from "gatsby-plugin-image"

const imgQuery = graphql`
  {
    image: file(relativePath: { eq: "FourAdvantagesPoints/plusIcon.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`

export function Plus(props: Omit<GatsbyImageProps, "image">) {
  const data = useStaticQuery(imgQuery)

  return <GatsbyImage loading="eager" {...props} image={getImage(data.image)!} />
}
