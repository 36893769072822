import scrollToElement from "@ecom/ui/utils/scrollToElement"

export default function scrollToEl(idEl: string) {
  const itemApp = document.getElementById(idEl)
  const itemHeader = document.querySelector("header")
  if (itemApp && itemHeader) {
    const itemPosition = itemApp.getBoundingClientRect().top + window.scrollY
    window.scrollTo({
      top: itemPosition - itemHeader.offsetHeight,
      behavior: "smooth",
    })
  } else if (itemApp) {
    scrollToElement(itemApp)
  }
}
