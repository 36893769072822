// extracted by mini-css-extract-plugin
export var bezProcentov = "fourAdvantagesPoints-module--bezProcentov--RQDLg";
export var blackFridayHalva = "fourAdvantagesPoints-module--blackFridayHalva--N3HHL";
export var dashed = "fourAdvantagesPoints-module--dashed--U+dqN";
export var halvaDlyaGamerov = "fourAdvantagesPoints-module--halvaDlyaGamerov--LE8qL";
export var headTitle = "fourAdvantagesPoints-module--headTitle--A-ERI";
export var img = "fourAdvantagesPoints-module--img--TL-hC";
export var indent = "fourAdvantagesPoints-module--indent--fx9gj";
export var inviteFriend = "fourAdvantagesPoints-module--inviteFriend--Lwigc";
export var left = "fourAdvantagesPoints-module--left--v0GsW";
export var nedeliUmnyhRassrochek = "fourAdvantagesPoints-module--nedeliUmnyhRassrochek--bXvSc";
export var newYear = "fourAdvantagesPoints-module--newYear--51GHp";
export var pkwBonus1500 = "fourAdvantagesPoints-module--pkwBonus1500--0VGZY";
export var pkwRedesign = "fourAdvantagesPoints-module--pkwRedesign--9OpaP";
export var pkwRedesignWithAnchor = "fourAdvantagesPoints-module--pkwRedesignWithAnchor--lFGv0";
export var rassrochkaNaAuto = "fourAdvantagesPoints-module--rassrochkaNaAuto--rbdDd";
export var redisignBezProcentov = "fourAdvantagesPoints-module--redisignBezProcentov--flgf9";
export var section = "fourAdvantagesPoints-module--section--6e2q+";
export var subtitle = "fourAdvantagesPoints-module--subtitle--hUJEQ";
export var text = "fourAdvantagesPoints-module--text--C+9ID";
export var title = "fourAdvantagesPoints-module--title--8QXcr";
export var wrap = "fourAdvantagesPoints-module--wrap---TxJ-";
export var wrapper = "fourAdvantagesPoints-module--wrapper--qGl-w";