import React from "react"

import { AdvantageType, Variants } from "./types"
import {
  Cashback,
  LootMoney,
  Services,
  Mounth,
  Calendar,
  MounthWithoutTwelve,
  Rocket,
  Plus,
  Wallet,
  CashbackBlack,
} from "./images"
import scrollToEl from "../../helpers/scrolls"

export const advantages: Record<Variants, AdvantageType[]> = {
  pkw: [
    {
      title: "До 17% на остаток",
      subtitle: "Дополнительный доход",
      img: <Mounth alt="MounthPayment image" />,
      required: true,
    },
    {
      title: "Кэшбэк до 10%",
      subtitle: "На любые покупки",
      img: <Cashback alt="Services image" />,
      required: true,
    },
    {
      title: <>Обслуживание 0&nbsp;₽</>,
      subtitle: "Навсегда и без условий",
      img: <Services alt="Services image" />,
      required: true,
    },
    {
      title: <>До 500 000&nbsp;₽</>,
      subtitle: "На оплату покупок<br>и снятие наличных",
      img: <LootMoney alt="loot money image" />,
    },
  ],
  bezProcentov: [
    {
      title: "24 месяца без %",
      subtitle: "Срок рефинансирования",
      img: <Calendar alt="calendar image" />,
      required: true,
    },
    {
      title: <>До 500 000&nbsp;₽</>,
      subtitle: "На оплату покупок ",
      img: <LootMoney alt="loot money image" />,
      required: true,
    },
    {
      title: <>Обслуживание 0&nbsp;₽</>,
      subtitle: "Навсегда и без условий",
      img: <Services alt="Services image" />,
    },
    {
      title: "Без визита в банк",
      subtitle: "Оформление онлайн",
      img: <Mounth alt="cylinder image" />,
      required: true,
    },
  ],
  refin: [
    {
      title: "Рассрочка до 24 месяцев",
      subtitle: "Рефинансирование",
      img: <Calendar alt="calendar image" />,
      required: true,
    },
    {
      title: <>До 300 000&nbsp;₽</>,
      subtitle: "Максимальная сумма",
      img: <LootMoney alt="loot money image" />,
      required: true,
    },
    {
      title: <>Обслуживание 0&nbsp;₽</>,
      subtitle: "Навсегда и без условий",
      img: <Services alt="Services image" />,
    },
    {
      title: "Без визита в банк",
      subtitle: "Оформление онлайн",
      img: <Mounth alt="cylinder image" />,
      required: true,
    },
  ],
  refinMedia: [
    {
      title: <>24 месяца без&nbsp;%</>,
      subtitle: "Срок рефинансирования",
      img: <Calendar alt="calendar image" />,
      required: true,
    },
    {
      title: <>До 300 000&nbsp;рублей</>,
      subtitle: "Максимальная сумма",
      img: <LootMoney alt="loot money image" />,
      required: true,
    },
    {
      title: <>Обслуживание 0&nbsp;₽</>,
      subtitle: "Навсегда и без условий",
      img: <Services alt="Services image" />,
    },
    {
      title: "Без визита в банк",
      subtitle: "Оформление онлайн",
      img: <Mounth alt="cylinder image" />,
      required: true,
    },
  ],
  pkwRedesign: [
    {
      title: "Рефинансирование",
      subtitle: "В рассрочку на 24 месяца",
      img: <Calendar alt="calendar image" />,
      required: true,
    },
    {
      title: <>До 500 000&nbsp;₽</>,
      subtitle: "На оплату покупок ",
      img: <LootMoney alt="loot money image" />,
      required: true,
    },
    {
      title: <>Обслуживание 0&nbsp;₽</>,
      subtitle: "Навсегда и без условий",
      img: <Services alt="Services image" />,
    },
    {
      title: "Без визита в банк",
      subtitle: "Оформление онлайн",
      img: <MounthWithoutTwelve alt="cylinder image" />,
      required: true,
    },
  ],
  pkwRedesignWithAnchor: [
    {
      title: (
        <div
          style={{ cursor: "pointer" }}
          role="button"
          tabIndex={0}
          onKeyPress={() => scrollToEl("shortBanner")}
          onClick={() => scrollToEl("shortBanner")}
        >
          Рефинансирование
        </div>
      ),
      subtitle: "В рассрочку на 24 месяца",
      img: <Calendar alt="calendar image" />,
      required: true,
      dashed: true,
    },
    {
      title: <>До 500 000&nbsp;₽</>,
      subtitle: "На оплату покупок ",
      img: <LootMoney alt="loot money image" />,
      required: true,
    },
    {
      title: <>Обслуживание 0&nbsp;₽</>,
      subtitle: "Навсегда и без условий",
      img: <Services alt="Services image" />,
    },
    {
      title: "Без визита в банк",
      subtitle: "Оформление онлайн",
      img: <MounthWithoutTwelve alt="cylinder image" />,
      required: true,
    },
  ],
  inviteFriend: [
    {
      title: "Рефинансирование",
      subtitle: "В рассрочку на 24 месяца",
      img: <Calendar alt="calendar image" />,
      required: true,
    },
    {
      title: <>До 500 000&nbsp;₽</>,
      subtitle: "Лимит рассрочки",
      img: <LootMoney alt="loot money image" />,
      required: true,
    },
    {
      title: <>Обслуживание 0&nbsp;₽</>,
      subtitle: "Навсегда и без условий",
      img: <MounthWithoutTwelve alt="cylinder image" />,
    },
    {
      title: "Кэшбэк до 10%",
      subtitle: "От покупок",
      img: <Services alt="Services image" />,
      required: true,
    },
  ],
  nedeliUmnyhRassrochek: [
    {
      title: <>До 500 000&nbsp;₽</>,
      subtitle: "на оплату покупок",
      required: true,
      img: <LootMoney alt="loot money image" />,
    },
    {
      title: <>Обслуживание 0&nbsp;₽</>,
      subtitle: "навсегда и без условий",
      img: <MounthWithoutTwelve alt="cylinder image" />,
    },
    {
      title: <>Кэшбэк до 10%</>,
      subtitle: "от покупок",
      img: <Services alt="Services image" />,
      required: true,
    },
    {
      title: "17% на остаток",
      subtitle: "по карте",
      img: <Cashback alt="Services image" />,
      required: true,
    },
  ],
  redisignBezProcentov: [
    {
      title: <>До 500 000&nbsp;₽</>,
      subtitle: "На оплату покупок ",
      img: <LootMoney alt="loot money image" />,
      required: true,
    },
    {
      title: "До 300 000 ₽",
      subtitle: "На рефинансирование",
      img: <Calendar alt="calendar image" />,
      required: true,
    },
    {
      title: <>Обслуживание 0&nbsp;₽</>,
      subtitle: "Навсегда и без условий",
      img: <Services alt="Services image" />,
    },
    {
      title: "Без визита в банк",
      subtitle: "Оформление онлайн",
      img: <MounthWithoutTwelve alt="cylinder image" />,
      required: true,
    },
  ],
  newYear: [
    {
      title: <>До 500 000&nbsp;₽</>,
      subtitle: "на оплату покупок",
      img: <LootMoney alt="loot money image" />,
    },
    {
      title: "Обслуживание 0 ₽",
      subtitle: "Навсегда и без условий",
      img: <MounthWithoutTwelve alt="MounthPayment image" />,
      required: true,
    },
    {
      title: <>Кэшбэк до 10%</>,
      subtitle: "от покупок",
      img: <Services alt="Services image" />,
      required: true,
    },
    {
      title: "17% на остаток",
      subtitle: "по карте",
      img: <Cashback alt="Services image" />,
      required: true,
    },
  ],

  productsCategories: [
    {
      title: <>Кэшбэк до 10%</>,
      subtitle: "Покупайте с выгодой",
      img: <Cashback alt="Services image" />,
      required: true,
    },
    {
      title: <>До 500 тыс. рублей</>,
      subtitle: "лимит на покупки",
      img: <LootMoney alt="loot money image" />,
    },
    {
      title: <>Обслуживание 0&#160;₽</>,
      subtitle: "Навсегда и без условий",
      img: <Services alt="Services image" />,
      required: true,
    },
    {
      title: "Без визита в банк",
      subtitle: "Оформление онлайн",
      img: <Mounth alt="cylinder image" />,
      required: true,
    },
  ],
  celebaRefin: [
    {
      title: <>Рассрочка до 24 месяцев</>,
      subtitle: "Рефинансирование",
      img: <Services alt="Services image" />,
      required: true,
    },
    {
      title: <>До 300 тыс. рублей</>,
      subtitle: "Максимальная сумма",
      img: <LootMoney alt="loot money image" />,
      required: true,
    },
    {
      title: "Без визита в банк",
      subtitle: "Оформление онлайн",
      img: <Mounth alt="cylinder image" />,
      required: true,
    },
  ],
  halvaDlyaGamerov: [
    {
      title: <>Пополнение Steam</>,
      subtitle: "и других игровых кошельков",
      img: <Wallet alt="wallet image" />,
      required: true,
    },
    {
      title: (
        <>
          Кэшбэк
          <br /> и доп.рассрочка
        </>
      ),
      subtitle: "в магазинах электроники",
      img: <CashbackBlack alt="cashback image" />,
      required: true,
    },
    {
      title: (
        <>
          Рассрочка <br className="d-none d-desk-only-block" /> на покупки
        </>
      ),
      subtitle: "в онлайн-магазинах игр<br>и внутри онлайн-игр",
      img: <Plus alt="plus image" />,
      required: true,
    },
    {
      title: (
        <>
          Скидки на <br className="d-none d-desk-only-block" /> абонемент
        </>
      ),
      subtitle: "в компьютерные клубы,<br>покупка в рассрочку",
      img: <Rocket alt="rocket image" />,
      required: true,
    },
  ],
  pkwBonus1500: [
    {
      title: "Рассрочка 24 мес",
      subtitle: "На рефинансирование",
      img: <Calendar alt="calendar image" />,
      required: true,
    },
    {
      title: <>Кэшбэк до 10%</>,
      img: <LootMoney alt="loot money image" />,
      required: true,
    },
    {
      title: <>Обслуживание 0&nbsp;₽</>,
      subtitle: "Навсегда и без условий",
      img: <Services alt="Services image" />,
    },
    {
      title: "До 500 000 ₽",
      subtitle: "Кредитный лимит на покупки",
      img: <MounthWithoutTwelve alt="cylinder image" />,
      required: true,
    },
  ],
  rassrochkaNaAuto: [
    {
      title: "Рефинансирование",
      subtitle: "В рассрочку на 24 месяца",
      img: <Calendar alt="calendar image" />,
      required: true,
    },
    {
      title: "Более 260 000",
      subtitle: "Партнеров по всей России",
      img: <LootMoney alt="loot money image" />,
      required: true,
    },
    {
      title: <>Обслуживание 0&nbsp;₽</>,
      subtitle: "Навсегда и без условий",
      img: <Services alt="Services image" />,
    },
    {
      title: <>Без визита в&nbsp;банк</>,
      subtitle: "Оформление онлайн",
      img: <MounthWithoutTwelve alt="cylinder image" />,
      required: true,
    },
  ],
  blackFridayHalva: [
    {
      title: "24 мес. рассрочки",
      subtitle: "На покупки у партнеров",
      img: <Calendar alt="calendar image" />,
      required: true,
    },
    {
      title: <>До 500 000&nbsp;₽</>,
      subtitle: "На оплату покупок ",
      img: <LootMoney alt="loot money image" />,
      required: true,
    },
    {
      title: <>Обслуживание 0&nbsp;₽</>,
      subtitle: "Навсегда и без условий",
      img: <Services alt="Services image" />,
    },
    {
      title: "Без визита в банк",
      subtitle: "Оформление онлайн",
      img: <MounthWithoutTwelve alt="cylinder image" />,
      required: true,
    },
  ],
}
